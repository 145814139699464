<template>
  <base-loader v-if="loading"></base-loader>

  <div class="row" v-if="orders">
    <div class="col-xl-4 mb-4" v-for="(order, index) in orders" :key="index">
      <div class="card card-profile shadow order-card">
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span class="badge badge-info">
              {{ $t("orders.order info") }}
            </span>
          </div>
          <img
            :src="
              'https://nqal.co/LiveNqal/public/' +
              order.OrderRequestsItems[0]['image']
            "
            class="w-100"
            :alt="order.OrderRequestsItems[0]['title']"
          />
          <div>
            <h3 class="mt-2 mb-0">
              {{ $t("orders.order") }}:
              <span>{{ order.OrderRequestsItems[0].title }}</span>
            </h3>
            <h3 class="mb-0">
              {{ $t("orders.description") }}:
              <span>{{ order.OrderRequestsItems[0].description }}</span>
            </h3>
            <h3>
              {{ $t("orders.value") }}:
              <span>{{ order.OrderRequestsItems[0].value }}</span>
            </h3>
            <h3>
              {{ $t("orders.price") }}:
              <span v-if="order.is_paid == 0">{{ order.item_price }}</span>
              <span v-else>{{ $t("orders.already paid") }}</span>
            </h3>
            <h3>
              {{ $t("orders.type") }}: <span>{{ order.order_type }}</span>
            </h3>
            <h3>
              {{ $t("orders.package size") }}:
              <span>{{ order.pack_size }}</span>
            </h3>
            <h3>
              {{ $t("orders.shipping_type") }}:
              <span>{{
                order.shippingtype == 1
                  ? $t("orders.light")
                  : order.shippingtype
              }}</span>
            </h3>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span class="badge badge-info">
              {{ $t("orders.customer") }}
            </span>
          </div>
          <div>
            <h3 class="mt-2 mb-0">
              {{ $t("orders.customer_name") }}:
              <router-link :to="`/customers/${order.customer_id}`">
                <span>{{ order.customer_name }}</span>
              </router-link>
            </h3>
            <h3 class="mt-2 mb-0">
              {{ $t("orders.customer_phone") }}:
              <span>{{ order.customer_phone }}</span>
            </h3>
            <h3 class="mt-2 mb-0">
              {{ $t("orders.receiver_name") }}:
              <span>{{ order.receiver_name }}</span>
            </h3>
            <h3 class="mt-2 mb-0">
              {{ $t("orders.receiver_phone") }}:
              <span>{{ order.receiver_phone }}</span>
            </h3>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span
              class="badge badge-success order-dis"
              v-if="order.distance <= 20"
              >{{ $t("global.inside city") }}: {{ order.distance }} KM</span
            >
            <span class="badge badge-danger order-dis" v-else
              >{{ $t("global.outside city") }}: {{ order.distance }} KM</span
            >
          </div>
          <div>
            <h3 class="mt-2 mb-0">
              {{ $t("orders.state") }}:
              <span>{{ order.state }}</span>
            </h3>
            <h3>
              {{ $t("global.from") }}: <span>{{ order.address_from }}</span>
            </h3>
            <h3>
              {{ $t("global.to") }}: <span>{{ order.address_to }}</span>
            </h3>
            <hr />
            <div v-if="order.delivery_date">
              <h3>
                {{ $t("orders.delivery day") }}:
                <span>{{ order.delivery_date.split(" ")[0] }}</span>
              </h3>
              <h3>
                {{ $t("global.from") }}:
                <span>{{ order.timeSlot.from_time }}</span>
                <br />
                {{ $t("global.to") }}:
                <span>{{ order.timeSlot.to_time }}</span>
              </h3>
            </div>
            <div v-else-if="order.now">
              <h3>
                {{ $t("orders.delivery day") }}:
                <span>{{ $t("orders.now") }}</span>
              </h3>
            </div>
            <div v-else-if="order.twenty_four_hour">
              <h3>
                {{ $t("orders.delivery day") }}:
                <span>{{ $t("orders.through_twenty_four_hour") }}</span>
              </h3>
            </div>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <div>
            <h3>
              {{ $t("orders.payment method") }}:
              <span v-if="(order.payment_methods_id = 1)">
                {{ $t("orders.cash or qr") }}
              </span>
              <span v-else>{{ $t("orders.your wallet") }}:</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Pagination Component -->
  <base-pagination
    :page="page"
    :total_pages="total_pages"
    v-on:pagination-num="page = $event"
  />
</template>

<script>
import axios from "axios";

export default {
  name: "requested-orders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      orders: "",
      page: 1,
      total_pages: "",
      statuses: "",
      current_status: "",
    };
  },
  created() {
    this.getOrders("admin/administration/ordersec/allrequestedOrders?page=1");
  },
  watch: {
    page: function () {
      this.getOrders(
        "admin/administration/ordersec/allrequestedOrders?page=" + this.page
      );
    },
  },
  methods: {
    getOrders: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.orders = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
